// By default, Klaro will load the config from a global "klaroConfig" variable.
// You can change this by specifying the "data-config" attribute on your
// script take, e.g. like this:
// <script src="klaro.js" data-config="myConfigVariableName" />
// You can also disable auto-loading of the consent notice by adding
// data-no-auto-load=true to the script tag.
//
const setSrc = function (elem) {
    const src = elem.attr("data-src");
    elem.attr("src", src);
    elem.attr("data-src", "");
};

// const enableFrames = function(){
//   const $iframes = jQuery('iframe');
//   $iframes.each(function() {
//     const $frame = jQuery(this);
//     setSrc($frame);
//   });
// }

const editConsentTab = function () {
    const tabClass = "klaro-consent-edit-tab";
    const tabText = "Cookie Settings";
    if (jQuery("." + tabClass).length > 0) {
        return;
    }
    const $tab = jQuery('<a href="#cookie-consent"></a>');
    $tab.text(tabText);
    $tab.addClass(tabClass);
    $tab.click(function (e) {
        e.preventDefault();
        klaro.show();
        jQuery(".cm-btn-success").click(function () {
            jQuery("body").removeClass("klaro-open");
        });
        const $revoke = jQuery('<button class="cm-btn cm-btn-sm cm-btn-danger cn-decline" type="button">Decline</button>');
        $revoke.click(function (e) {
            e.preventDefault();
            klaro.getManager().resetConsent();
            location.reload();
        });
        jQuery(".cookie-modal .cm-footer").append($revoke);
    });
    jQuery(".footer--copyright .block p").append("&nbsp;&nbsp; ").append($tab);
};

const allCookies = function (cookies) {
    let arr = [];
    for (var i = 0; i < cookies.length; i++) {
        let c = cookies[i];
        arr.push(c);
        arr.push([c, "/", location.hostname]);
        arr.push([c, "/", "." + location.hostname]);
        if (location.hostname.split(".").length > 2) {
            arr.push([c, "/", location.hostname.split(".").splice(1).join(".")]);
            arr.push([c, "/", "." + location.hostname.split(".").splice(1).join(".")]);
        }
    }
    return arr;
};

const klaroDescription =
    "We use third-party cookies to provide content like videos, to improve the performance, or analyze traffic to our site, and/or to personalize your experience of our site. You can control the use of cookies through your browser's settings or by selecting the options below.";

window.klaroConfig = {
    // You can customize the ID of the DIV element that Klaro will create
    // when starting up. If undefined, Klaro will use 'klaro'.
    elementID: "klaro",

    // You can customize the name of the cookie that Klaro uses for storing
    // user consent decisions. If undefined, Klaro will use 'klaro'.
    cookieName: "klaro",

    // You can also set a custom expiration time for the Klaro cookie.
    // By default, it will expire after 120 days.
    cookieExpiresAfterDays: 365,

    // You can customize the name of the cookie that Klaro will use to
    // store user consent. If undefined, Klaro will use 'klaro'.

    // Put a link to your privacy policy here (relative or absolute).
    privacyPolicy: "/privacy",

    // Defines the default state for applications (true=enabled by default).
    default: false,

    // If "mustConsent" is set to true, Klaro will directly display the consent
    // manager modal and not allow the user to close it before having actively
    // consented or declines the use of third-party apps.
    mustConsent: false,

    // You can define the UI language directly here. If undefined, Klaro will
    // use the value given in the global "lang" variable. If that does
    // not exist, it will use the value given in the "lang" attribute of your
    // HTML tag. If that also doesn't exist, it will use 'en'.
    //lang: 'en',

    // You can overwrite existing translations and add translations for your
    // app descriptions and purposes. See `src/translations.yml` for a full
    // list of translations that can be overwritten:
    // https://github.com/DPKit/klaro/blob/master/src/translations.yml

    // Example config that shows how to overwrite translations:
    // https://github.com/DPKit/klaro/blob/master/src/configs/i18n.js
    decline: false,
    languages: ['en', 'fr', 'zh', 'th', 'ko', 'ja', 'fi', 'es', 'nl', 'de', 'pt', 'pl', 'ms'],
    translations: {
        en: {
            ok: "I accept",
            save: "Save",
            close: "Save",
            decline: "decline",
            acceptAll: "Accept all Cookies",
            required: {
                title: "hello",
            },
            // Small modal
            consentNotice: {
                learnMore: "Select cookies",
                description: klaroDescription,
            },
            // Full modal
            consentModal: {
                description: klaroDescription,
            },
            "google-analytics": {
                description: "Collects data to help us improve the website.",
            },
            Videos: {
                description: "Allow videos from Vimeo, YouTube, etc. demo",
            },
            privacyPolicy: {
              text: "Please find more information on the cookies used on our site {privacyPolicy}.\n",
            },
            privacyPolicyLabel: "Privacy Policy",
            purposes: {
                analytics: {
                    title: "Analytics",
                },
                social: {
                    title: "Social Media",
                },
                Videos: {
                    title: "Videos and Iframes",
                },
                kentico_session: {
                    title: "Sessions",
                },
            },
        },

        fr: {
            ok: "J'accepte",
            save: "Sauvegarder",
            decline: "Refuser",
            acceptAll: "Accepter tout",
            required: {
                title: "Bonjour",
            },
            // Small modal
            consentNotice: {
                learnMore: "Sélectionnez les Cookies",
            },
            // Full modal
            kentico_session: {
                description: "Collecte les données de navigation pour nous aider à améliorer le site",
            },
            analytics: {
                description: "Données analytiques Google",
            },
            sharethis: {
              description: "Affiche les boutons de partage réseaux sociaux",
            },
            recaptcha: {
              description: 'Prévention des Spams pour formulaires.'
            },
            videos: {
                description: "Démo Autoriser les vidéos Vimeo, Youtube etc...",
            },
            privacyPolicy: {
              text: "Plus d'informations au sujet de l'utilisation des Cookies sur notre site {privacyPolicy}.\n",
            },
            privacyPolicyLabel: "politique de confidentialité",
            purposes: {
                analytics: {
                    title: "Analytiques",
                },
                kentico_session: {
                    title: "Se connecter",
                },
                videos: {
                    title: "Vidéos et Iframes",
                }
            },
        },

        zh: {
            ok: "我接受",
            save: "保存",
            decline: "拒绝",
            acceptAll: "照单全收",
            required: {
                title: "您好",
            },
            // Small modal
            consentNotice: {
                learnMore: "选择Cookie",
            },
            kentico_session: {
                description: "收集数据，以帮助我们改进网站",
            },
            "google-analytics": {
                description: "收集数据，以帮助我们改进网站",
            },
            sharethis: {
              description: "提供在社交媒体平台上分享内容的按钮",
            },
            recaptcha: {
              description: "防止表单垃圾邮件"
            },
            videos: {
                description: "允许观看Vimeo、YouTube等网站的视频演示",
            },
            privacyPolicy: {
              text: "请查找有关在我们网站上使用cookie的更多信息 {privacyPolicy}.\n",
            },
            privacyPolicyLabel: "隱私權政策",
            purposes: {
                analytics: {
                    title: "分析",
                },
                kentico_session: {
                    title: "登入",
                },
                videos: {
                    title: "视频和Iframes",
                }
            },
        },

        th: {
            ok: "ฉันยอมรับ",
            save: "บันทึก",
            decline: "ปฏิเสธ",
            acceptAll: "ยอมรับทั้งหมด",
            required: {
                title: "สวัสดี",
            },
            // Small modal
            consentNotice: {
                description:"เราใช้คุกกี้ของบุคคลที่สามเพื่อนำเสนอเนื้อหา เช่น วิดีโอ เพื่อปรับปรุงประสิทธิภาพ หรือวิเคราะห์การเข้าชมไซต์ของเรา และ/หรือเพื่อปรับแต่งประสบการณ์ของคุณในไซต์ของเรา คุณสามารถควบคุมการใช้คุกกี้ผ่านการตั้งค่าเบราว์เซอร์ของคุณหรือโดยการเลือกตัวเลือกด้านล่าง",
                learnMore: "เลือกคุกกี้",
            },
            "google-analytics": {
                description: "รวบรวมข้อมูลเพื่อช่วยเหลือเราในการปรับปรุงเว็บไซต์",
            },
            Videos: {
                description: "อนุญาตให้ใช้วิดีโอจาก Vimeo, YouTube และวิดีโอสาธิตอื่น ๆ",
            },
            privacyPolicyLabel: "นโยบายความเป็นส่วนตัว",
            privacyPolicy: {
              text: "โปรดดูข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้ที่ใช้ในไซต์ของเรา {privacyPolicy}.\n",
              name: "นโยบายความเป็นส่วนตัว"
            },
            consentModal: {
                description:"เราใช้คุกกี้ของบุคคลที่สามเพื่อนำเสนอเนื้อหา เช่น วิดีโอ เพื่อปรับปรุงประสิทธิภาพ หรือวิเคราะห์การเข้าชมไซต์ของเรา และ/หรือเพื่อปรับแต่งประสบการณ์ของคุณในไซต์ของเรา คุณสามารถควบคุมการใช้คุกกี้ผ่านการตั้งค่าเบราว์เซอร์ของคุณหรือโดยการเลือกตัวเลือกด้านล่าง"
            },
            purposeItem: {
                service: "บริการ"
            },
            service: {
                required: {
                    title: "จำเป็นเสมอ"
                },
                disableAll: {
                    title: "เปิดหรือปิดบริการทั้งหมด",
                    description: "ใช้สวิตช์นี้เพื่อเปิดหรือปิดใช้งานบริการทั้งหมด"
                },
                purpose: "วัตถุประสงค์"
            },
            acceptSelected: "ยอมรับรายการที่เลือก",
            poweredBy: "Powered by Klaro",
            purposes: {
                login: {
                    title: "เข้าสู่ระบบ"
                },
                analytics: {
                    title: "กูเกิล แอนะลิติกส์",
                },
                kentico_session: {
                    title: "เข้าสู่ระบบ",
                },
                videos: {
                    title: "วิดีโอและไอเฟรม",
                }
            },
        },

        ko: {
            ok: "수락",
            save: "저장",
            decline: "거부",
            acceptSelected: "선택 항목 수락",
            acceptAll: "모든 쿠키 허용",
            required: {
                title: "안녕하세요",
            },
            // Small modal
            consentNotice: {
                description:"당사는 타사 쿠키를 사용하여 동영상과 같은 콘텐츠를 제공하거나, 성능을 개선하거나, 사이트 트래픽을 분석하거나, 귀하의 사이트 경험을 맞춤화합니다. 브라우저의 설정을 통해 또는 아래 옵션을 선택하여 쿠키 사용을 제어할 수 있습니다",
                learnMore: "쿠키 선택",
            },
            "google-analytics": {
                description: "웹사이트를 개선하는 데 도움이 되는 데이터를 수집합니다.",
            },
            Videos: {
                description: "Vimeo, YouTube 등의 동영상 데모를 허용합니다.",
             },
            privacyPolicyLabel: "개인 정보 정책",
            privacyPolicy: {
                text: "당사 사이트에서 사용되는 쿠키에 대한 자세한 내용은 {privacyPolicy}을 참조하세요.\n",
                name: "개인 정보 정책"
            },
            consentModal: {
                description:"당사는 타사 쿠키를 사용하여 동영상과 같은 콘텐츠를 제공하거나, 성능을 개선하거나, 사이트 트래픽을 분석하거나, 귀하의 사이트 경험을 맞춤화합니다. 브라우저의 설정을 통해 또는 아래 옵션을 선택하여 쿠키 사용을 제어할 수 있습니다"
            },
            purposeItem: {
                service: "서비스"
            },
            service: {
                required: {
                    title: "항상 필수"
                },
                disableAll: {
                    title: "모든 서비스 활성화 또는 비활성화",
                    description: "이 스위치를 사용하여 모든 서비스를 활성화 또는 비활성화합니다."
                },
                purpose: "목적"
            },
            poweredBy: "Powered by Klaro",
            purposes: {
                login: {
                    title: "로그인"
                },
                analytics: {
                    title: "애널리틱스",
                },
                kentico_session: {
                    title: "로그인",
                },
                videos: {
                    title: "동영상 및 Iframe",
                }
            },
        },

        ja: {
            ok: "承諾する",
            save: "保存する",
            decline: "拒否する",
            acceptAll: "すべてを受け入れる",
            acceptSelected: "選択を受け入れる",
            required: {
                title: "こんにちは",
            },
            // Small modal
            consentNotice: {
                description:"当社は、ビデオなどのコンテンツを提供するため、当社サイトのパフォーマンスを向上させるため、またはトラフィックを分析するため、および/または当社サイトでの体験をパーソナライズするために、サードパーティのクッキーを使用します。クッキーの使用は、ブラウザの設定または以下のオプションを選択することで制御できます。",
                learnMore: "クッキーを選択する",
            },
            "google-analytics": {
                description: "ウェブサイト改善のためにデータを収集します",
            },
            Videos: {
                description: "Vimeo、YouTubeなどの動画のデモを許可する",
            },
            privacyPolicyLabel: "プライバシーポリシー",
            privacyPolicy: {
                text: "弊社サイト{privacyPolicy}で、サイトが使用するクッキーについての詳しい情報をご覧ください。",
                name: "プライバシーポリシー"
            },
            consentModal: {
                description:"当社は、ビデオなどのコンテンツを提供するため、当社サイトのパフォーマンスを向上させるため、またはトラフィックを分析するため、および/または当社サイトでの体験をパーソナライズするために、サードパーティのクッキーを使用します。クッキーの使用は、ブラウザの設定または以下のオプションを選択することで制御できます。"
            },
            purposeItem: {
                service: "サービス"
            },
            service: {
                required: {
                    title: "必ず必要"
                },
                disableAll: {
                    title: "すべてのサービスを有効または無効にする",
                    description: "このスイッチを使用して、すべてのサービスを有効または無効にする。"
                },
                purpose: "目的"
            },
            poweredBy: "Powered by Klaro",
            purposes: {
                login: {
                    title: "ログイン"
                },
                analytics: {
                    title: "アナリティクス",
                },
                kentico_session: {
                    title: "ログイン",
                    description: "ウェブサイトの改善に役立つデータを収集します。"
                },
                videos: {
                    title: "動画とインラインフレーム",
                }
            },
        },

        fi: {
            acceptAll: "Hyväksy kaikki",
            acceptSelected: "Hyväksy valitut",
            consentNotice: {
                description: "Käytämme kolmannen osapuolen evästeitä tarjotaksemme sisältöä, kuten videoita, parantaaksemme suorituskykyä tai analysoidaksemme sivustollemme tulevaa liikennettä ja/tai mukauttaaksemme käyttökokemustasi sivustollamme. Voit hallita evästeiden käyttöä selaimesi asetuksista tai valitsemalla alla olevista vaihtoehdoista.",
            },
            privacyPolicyLabel: "Tietosuojakäytäntö",
            privacyPolicy: {
                text: "Saat lisätietoja sivustomme käyttämistä evästeistä sivustoltamme {privacyPolicy}.",
                name: "Tietosuojakäytäntö"
            },
            purposeItem: {
                service: "Palvelu"
            },
            purposes: {
                login: {
                    title: "Kirjaudu sisään"
                },
                analytics: {
                    title: "Analytiikka",
                },
                kentico_session: {
                    title: "Istunto",
                    description: "Keräämme tietoja, jotka auttavat meitä parantamaan verkkosivustoamme."
                },
                videos: {
                    title: "Videot ja iframe-kehykset",
                }
            },
        },

        es: {
            consentNotice: {
                description: "Utilizamos cookies de terceros para proporcionar contenido como videos, mejorar el rendimiento, analizar el tráfico a nuestro sitio y/o personalizar su experiencia en nuestro sitio. Puede controlar el uso de cookies a través de la configuración de su navegador o seleccionando las siguientes opciones.",
            },
            acceptAll: "Aceptar todas",
            acceptSelected: "Aceptar seleccionadas",
            privacyPolicyLabel: "política de privacidad",
            privacyPolicy: {
                name: "política de privacidad",
                text: "Para saber más, por favor lea nuestra {privacyPolicy}",
            }

        },

        nl: {
            consentNotice: {
                description: "Hier kunt u de diensten die wij op deze website willen gebruiken beoordelen en aanpassen. U heeft de leiding! Schakel de diensten naar eigen inzicht in of uit.",
            },
            acceptAll: "Accepteer alle",
            acceptSelected: "Geselecteerde",
            privacyPolicyLabel: "privacybeleid",
            privacyPolicy: {
                name: "privacybeleid",
                text: "Voor meer informatie kunt u ons {privacyPolicy} lezen.",
            }

        },

        de: {
            consentNotice: {
                description: "Hier können Sie die Dienste, die wir auf dieser Website nutzen möchten,bewerten und anpassen. Sie haben das Sagen! Aktivieren oder deaktivieren Sie die Dienste, wie Sie es für richtig halten.",
            },
            acceptAll: "Alle akzeptieren",
            acceptSelected: "Ausgewählte akzeptieren",
            privacyPolicyLabel: "Datenschutzerklärung",
            privacyPolicy: {
                name: "Datenschutzerklärung",
                text: "Um mehr zu erfahren, lesen Sie bitte unsere {privacyPolicy}",
            }
        },

        pt: {
            consentNotice: {
                description: "Aqui você pode avaliar e personalizar os serviços que gostaríamos de usar neste website. Você está no comando! Habilite ou desabilite os serviços como julgar conveniente"
            },
            acceptAll: "Aceitar todos",
            acceptSelected: "Aceitar selecionados",
            privacyPolicyLabel: "política de privacidade",
            privacyPolicy: {
                name: "política de privacidade",
                text: "Para saber mais, por favor, leia nossa {privacyPolicy}.",
            }
        },

        pl: {
            consentNotice: {
                description: "Tutaj mogą Państwo ocenić i dostosować usługi, które chcielibyśmy wykorzystać na tej stronie. Włączaj lub wyłączaj usługi według własnego uznania",
            },
            acceptAll: "Zaakceptuj wszystkie",
            acceptSelected: "Zaakceptuj wybrane",
            privacyPolicyLabel: "polityka prywatności",
            privacyPolicy: {
                name: "polityka prywatności",
                text: "Aby dowiedzieć się więcej, prosimy o zapoznanie się z naszą {privacyPolicy}",
            }
        },

        ms: {
            ok: "Saya terima",
            save: "Simpan",
            decline: "tolak",
            acceptAll: "Terima semua",
            acceptSelected: "Terima Dipilih",
            required: {
                title: "Diperlukan",
            },
            // Small modal
            consentNotice: {
                description:"Kami menggunakan kuki pihak ketiga untuk menyediakan kandungan seperti video, untuk meningkatkan prestasi, atau menganalisis trafik ke tapak kami, dan/atau untuk memperibadikan pengalaman anda terhadap tapak kami. Anda boleh mengawal penggunaan kuki melalui tetapan penyemak imbas anda atau dengan memilih pilihan di bawah.",
                learnMore: "Pilih Kuki",
            },
            "google-analytics": {
                description: "Mengumpul data untuk membantu kami menambah baik laman web",
            },
            Videos: {
                description: "Benarkan video daripada demo Vimeo, YouTube, dll.",
            },
            privacyPolicyLabel: "Dasar Privasi",
            privacyPolicy: {
                text: "Sila dapatkan maklumat lanjut tentang kuki yang digunakan di laman web kami {privacyPolicy}.",
                name: "Dasar Privasi"
            },
            consentModal: {
                description:"Kami menggunakan kuki pihak ketiga untuk menyediakan kandungan seperti video, untuk meningkatkan prestasi, atau menganalisis trafik ke tapak kami, dan/atau untuk memperibadikan pengalaman anda terhadap tapak kami. Anda boleh mengawal penggunaan kuki melalui tetapan penyemak imbas anda atau dengan memilih pilihan di bawah."
            },
            purposeItem: {
                service: "perkhidmatan"
            },
            service: {
                required: {
                    title: "Diperlukan"
                },
                disableAll: {
                    title: "Dayakan atau lumpuhkan semua perkhidmatan",
                    description: "Gunakan suis ini untuk mendayakan atau melumpuhkan semua perkhidmatan."
                },
                purpose: "tujuan"
            },
            poweredBy: "Powered by Klaro",
            purposes: {
                login: {
                    title: "Log masuk"
                },
                analytics: {
                    title: "Analisis",
                },
                kentico_session: {
                    title: "Sesi",
                    description: "Kami mengumpul data yang membantu kami menambah baik tapak web kami."
                },
                videos: {
                    title: "Video dan iframe",
                }
            },
        }

    },
    // This is a list of third-party apps that Klaro will manage for you.
    services: [
        //session
        {
            name: "kentico_session",
            title: "Login and Forms",
            purposes: ["login"],
            cookies: allCookies([
                "ARRAffinity",
                ".ASPXFORMSAUTH",
                "ASP.NET_SessionId",
                "CMSCookieLevel",
                "CMSCsrfCookie",
                "CMSPreferredUICulture",
                "CMSSplitMode",
                "CMSViewMode",
                "klaro",
                "welcomeMessage",
            ]),
            default: true,
            required: true,
            callback: function (consent, app) {
                editConsentTab();
                //if (consent) {
                //  //enableFrames('session-opt-in');
                //  // Implement iframe resize on careers iframes
                //  // @see https://github.com/davidjbradshaw/iframe-resizer
                //  var $frames = jQuery('iframe[src*="crowneurope.hua.hrsmart.com"]');
                //  if ($frames.length && jQuery.fn.iFrameResize.length) {
                //    var iframe_options = {
                //      'log': false,
                //      'checkOrigin': false,
                //      'autoResize': true,
                //      'bodyMargin': '10px 10px 20px 10px'
                //    };
                //    $frames.iFrameResize(iframe_options);
                //  }
                //}
            },
        },
        //videos
        {
            name: "Videos",
            title: "Videos",
            purposes: ["videos"],
            default: false,
            required: false,
            cookies: ["vuid", "/", ".vimeo.com"],
            callback: function (consent, app) {
                const $iframes = jQuery('iframe[type="video-opt-in"]');
                $iframes.each(function () {
                    const $frame = jQuery(this);
                    if (consent) {
                        setSrc($frame);
                    }
                });
            },
        },
        //recaptcha
        // {
        //   name: 'recaptcha',
        //   title: 'Google reCAPTCHA',
        //   purposes: ['security', 'login', 'forms'],
        //   cookies: [
        //     'CONSENT',
        //     'NID',
        //     '1P_JAR',
        //     'ANID',
        //     'APISID',
        //     'HSID',
        //     'NID',
        //     'SAPISID',
        //     'SEARCH_SAMESITE',
        //     'SID',
        //     'SIDCC',
        //     'SSID',
        //     'DrupalVisitorMobile',
        //     'SimpleSAMLSessionID',
        //     's_cc',
        //     's_sq',
        //     's_vi'
        //   ],
        //   default: false,
        //   callback: function(consent, app) {

        //     if (consent) {
        //       const recaptchaScript = jQuery("[data-name^='recaptcha_api']");
        //       setSrc(recaptchaScript);
        //       enableFrames('captcha-opt-in');
        //       jQuery('.iframe-cookie-notice.recaptcha').hide();
        //     }
        //   }
        // },
        //analytics
        {
            name: "google-analytics",
            default: false,
            title: "Google Analytics",
            purposes: ["analytics"],
            cookies: allCookies(["_fbp", "_ga", /^_gat/, "_gid"]),
            required: false,
            //callback: function(consent, app) {
            //  if (consent) {
            //    if (typeof googleAnalyticsCallback == 'function') {
            //      googleAnalyticsCallback();
            //    }
            //  }
            //},
            onlyOnce: false,
        },
        // share this
        // {
        //   name: 'sharethis',
        //   title: 'ShareThis',
        //   purposes: ['social'],
        //   cookies: [
        //     'ASDT',
        //     'CSDT',
        //     'EE',
        //     'IDE',
        //     'IQAdobeCookieSync',
        //     'IQMID',
        //     'IQPData',
        //     'IQver',
        //     'ONPLFTRH',
        //     'SEUNCY',
        //     'TDCPM',
        //     'TDID',
        //     'U',
        //     '__cfduid',
        //     '__stid',
        //     '_rlid',
        //     '_rlmp1',
        //     'ab',
        //     'bkdc',
        //     'bku',
        //     'demdex',
        //     'dextp',
        //     'dpm',
        //     'intentIQ',
        //     'intentIQCDate',
        //     'mako_uid',
        //     'pxcelBcnLcy',
        //     'pxcelPage_c010',
        //     'u',
        //     'ud',
        //     'zc',
        //     '__unam',
        //     '__stid'
        //   ],
        //   default: false,
        //   optOut: false,
        //   callback: function(consent, app) {
        //     // if (consent) {
        //     //   shareThisCallback(jQuery, Drupal);
        //     // }
        //   }
        // }
    ],
};

jQuery(document).ready(function (e) {
    let defaultConsentDebounce;
    let currLanguage = jQuery('html').attr('lang');
    let currlang = currLanguage.substring(0, 2);
    if (klaro) {
        const cookieNoticeEl = $("#klaro .cookie-notice");
        const cookieModalEl = $("#klaro .cookie-modal");

        // Just to make sure it loads the first time
        if (cookieNoticeEl.length > 0) {
            cookieNotice();
        } else if (cookieModalEl.length > 0) {
            cookieModal();
        }

        jQuery(document).on('DOMNodeInserted', function(ev) {
          if (jQuery(ev.target).hasClass('cookie-notice') || jQuery(ev.target).hasClass('klaro')) {
            cookieNotice();
          } else if (jQuery(ev.target).hasClass('cookie-modal')) {
            cookieModal();
          }
        });

        function cookieNotice() {
            if (jQuery(".cookie-notice .cn-ok .custom-btn").length < 1) {
                const $privacyText = jQuery("<span></span>");
                let translationTxt = window.klaroConfig.translations[currlang];
                if(currLanguage === 'pt-BR' || currLanguage === 'pt-PT') {
                    translationTxt = window.klaroConfig.translations[currlang];
                }

                let privacyLink = '<a class="privacy-link" href="' + window.klaroConfig.privacyPolicy + '">' + (translationTxt.privacyPolicyLabel !== "undefined" ? translationTxt.privacyPolicyLabel : "privacy policy") + "</a>";
                $privacyText.html(" " + translationTxt.privacyPolicy.text.replace("{privacyPolicy}", privacyLink));

                const $body = jQuery(".cookie-notice .cn-body p:first-child");
                $body.append($privacyText);

                // Create an "accept all" button
                const $all = jQuery('<button class="">'+ translationTxt.acceptAll +'</button>');
                $all.click(function () {
                    for (var consent in klaro.getManager().consents) {
                        klaro.getManager().consents[consent] = true;
                    }
                    klaro.getManager().updateConsent();
                    klaro.getManager().saveAndApplyConsents();
                    location.reload();
                });
                //hide accept and decline buttons
                jQuery(".cookie-notice .cn-ok .cn-decline").hide();
                jQuery(".cookie-notice .cn-ok .cm-btn-success").hide();
                //add accept all cookies and select cookies...buttons
                jQuery(".cookie-notice .cn-ok").append($all);
                //jQuery('.cookie-notice .cn-ok').append('<button style="color:black;" class="" onclick="klaro.show()">Select cookies...</button>');
            }
        }
        function cookieModal() {
            jQuery(".cookie-modal .cm-footer-buttons .cn-decline").hide();
            jQuery(".cm-toggle-all").click(function () {
                clearTimeout(defaultConsentDebounce);
                defaultConsentDebounce = setTimeout(function () {
                    // force required to be true
                    let apps = window.klaroConfig.apps;
                    for (let i = 0; i < apps.length; i++) {
                        if (apps[i].required) {
                            klaro.getManager().consents[apps[i].name] = true;
                        }
                    }
                }, 100);
            });
            jQuery("button").click(function () {
                location.reload();
            });
        }
        klaro.initialize();
    } else {
        console.error("klaro not found");
    }
});
